<template lang="pug">
.candidate-edit-dialog
  CreateEditDialog(
    :item="candidate"
    title="Update Candidate"
    :isOpen="isOpen"
    @close="$emit('close')"
    @submit-form="saveCandidiate"
  )
    template(
      v-slot:form="{ clone, rules }"
    )
      .d-flex.justify-content-between.gap-8
        v-text-field(
          outlined
          dense
          label="First Name"
          v-model="clone.firstName"
          :rules="[rules.required]"
        )

        v-text-field(
          outlined
          dense
          label="Last Name"
          v-model="clone.lastName"
          :rules="[rules.required]"
        )

      .d-flex.justify-content-between.gap-8
        v-text-field(
          outlined
          dense
          label="Email"
          v-model="clone.email"
          :rules="[rules.required]"
        )

        v-text-field(
          outlined
          dense
          label="Phone"
          v-model="clone.phone"
        )

      AddressForm(
        :rules="rules"
        :clone="clone"
      )

      h5 Treasurer
      v-divider.mb-6

      .d-flex.justify-content-between.gap-8
        v-text-field(
          outlined
          dense
          label="First Name"
          v-model="clone.treasurerFirstName"
          :rules="[rules.required]"
        )

        v-text-field(
          outlined
          dense
          label="Last Name"
          v-model="clone.treasurerLastName"
          :rules="[rules.required]"
        )

      AddressForm(
        :rules="rules"
        :clone="clone"
        addressKey="treasurerAddress"
      )

</template>

<script>
import AddressForm from '@/components/Address.Form'
import useClone from '@/helpers/useClone'
import CreateEditDialog from '@/components/Dialogs/CreateEdit.Dialog'

export default {
  name: 'CandidateEditDialog',

  components: {
    AddressForm,
    CreateEditDialog
  },

  props: {
    title: {
      type: String
    },

    isOpen: Boolean,

    item: {
      type: Object,
      required: false,
      default: null
    }
  },

  setup(props, { emit, root: { $FeathersVuex, $snackSuccess, $snackError } }) {
    const { Candidate } = $FeathersVuex.api
    const candidate = new Candidate(props.item)

    const saveCandidiate = async ({ save, clone }) => {
      try {
        await save()
        const message = 'Candidate saved';
        $snackSuccess(message)
        emit('close')
      } catch (e) {
        $snackError('Error saving the Candidate.');
      }
    }

    return {
      candidate,
      saveCandidiate
    }
  }
}
</script>

<style>

</style>