<template lang="pug">
v-dialog(
  v-model="isOpen", 
  width="290px"
)
  template(v-slot:activator="{ on, attrs }")
    v-text-field(
      :class="inputClass"
      v-model="formatted", 
      :label="label", 
      append-icon="mdi-calendar", 
      readonly, 
      outlined
      dense
      v-bind="attrs", 
      v-on="on"
    )
  v-date-picker(
    color="primary"
    v-model="date"
    @input="close"
  )
</template>

<script>
import { ref, computed } from '@vue/composition-api'

export default {
  name: 'DatePickerDialog',

  props: {
    value: String,
    label: String,
    inputClass: String,
  },

  setup(props, { emit, root: { $day } }) {
    const defaultDate = new Date().toISOString().substring(0, 10)
    const date = ref(props.value ? props.value.substring(0, 10) : defaultDate);
    const isOpen = ref(false);
    const formatted = computed(() => $day(date.value).format('MMM Do, YYYY'))

    const close = () => {
      isOpen.value = false;
      emit('input', $day(date.value).startOf('day').toISOString());
    }

    return { 
      close, 
      isOpen, 

      formatted, 
      date 
    }
  }
}
</script>

<style>

</style>