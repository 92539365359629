<template lang="pug">
.campaign-edit-dialog
  CreateEditDialog(
    :item="campaign"
    title="Update Campaign"
    :isOpen="isOpen"
    @close="$emit('close')"
    @submit-form="saveCampaign"
  )
    template(
      v-slot:form="{ clone, rules }"
    )
      .d-flex.justify-content-between.gap-8
        v-select(
          outlined
          dense
          class="w-1/2"
          label="Chamber"
          v-model="clone.chamber"
          :items="chambers"
        )

        v-text-field(
          outlined
          dense
          class="w-1/2"
          label="District Number"
          v-model="clone.districtNumber",
          :rules="[rules.isNumeric]"
        )

      .d-flex.justify-content-between.gap-8
        v-select(
          outlined
          dense
          class="w-1/2"
          label="Support Level"
          v-model="clone.supportLevel"
          :items="supportLevels"
        )

        v-select(
          outlined
          dense
          class="w-1/2"
          label="Party"
          v-model="clone.party"
          :items="parties"
        )

      .d-flex.justify-content-between.gap-8
        div( class="w-1/2 mb-6" )
          AmountFormItem(
            label="Campaign Budget"
            v-model="campaign.budget"
            :rules="rules"
          )
        div( class="w-1/2" )

      .d-flex.justify-content-between.gap-8
        DatePickerDialog(
          label="Primary Election Date"
          v-model="clone.primaryElectionDate"
        )

        DatePickerDialog(
          label="General Election Date"
          v-model="clone.generalElectionDate"
        )
</template>

<script>
import AddressForm from '@/components/Address.Form'
import CreateEditDialog from '@/components/Dialogs/CreateEdit.Dialog'
import { chambers, supportLevels, parties } from '@/helpers/campaignData'
import DatePickerDialog from '@/components/DatePicker.Dialog'
import AmountFormItem from '@/components/Amount.FormItem'

export default {
  name: 'CampaignEditDialog',

  components: {
    AddressForm,
    CreateEditDialog,
    DatePickerDialog,
    AmountFormItem
  },

  props: {
    title: {
      type: String
    },

    isOpen: Boolean,

    item: {
      type: Object,
      required: false,
      default: null
    }
  },

  setup(props, { emit, root: { $FeathersVuex, $snackSuccess, $snackError } }) {
    const { Campaign } = $FeathersVuex.api
    const campaign = new Campaign(props.item)

    const saveCampaign = async ({ save }) => {
      try {
        await save()
        const message = 'Campaign saved';
        $snackSuccess(message)
        emit('close')
      } catch (e) {
        $snackError('Error saving the Campaign.');
      }
    }

    return {
      campaign,
      saveCampaign,
      chambers,
      supportLevels,
      parties
    }
  }
}
</script>

<style>

</style>