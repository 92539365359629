<template lang="pug">
v-text-field(
  class="min-w-0"
  :label="label"
  v-model="amount"
  outlined
  dense
  hide-details
  append-icon="mdi-currency-usd"
  :rules="[rules.isMoney]"
  @keyup="$emit('input', convert(amount, 'toNum'));"
)
</template>

<script>
import { convert } from '@/helpers/data'
import { ref } from '@vue/composition-api';

export default {
  name: 'AmountForm',

  props: {
    label: { type: String, default: 'Amount' },
    value: { type: Number },
    rules: { type: Object }
  },

  setup(props) {
    const amount = ref(convert(props.value, 'toMoney'));

    return {
      amount,
      convert
    }
  }
}
</script>
