<template lang="pug">
div( class="w-full px-8 xl:px-0 xl:w-8/12 2xl:w-1/2 mx-auto" )
  div.mb-8.mt-16( v-if="!isPending && candidate" )
    .d-flex.justify-between.align-end.mb-1
      h5 Candidate
      v-btn(
        color="primary"
        small
        depressed
        @click="openCandidateEdit = true"
        class="group-hover:opacity-100"
      )
        | update
    v-divider.mb-5

    dl(class='sm:grid-cols-2 grid grid-cols-1 gap-x-4 gap-y-8')
      div(class='sm:col-span-1')
        dt.text-sm.font-medium.text-gray-500 Full name
        dd.mt-1.text-sm.text-gray-900 {{ candidate.fullName }}

      div(class='sm:col-span-1')
        dt.text-sm.font-medium.text-gray-500 Email
        dd.mt-1.text-sm.text-gray-900 {{ candidate.email }}
        
      div(class='sm:col-span-1')
        dt.text-sm.font-medium.text-gray-500 Phone
        dd.mt-1.text-sm.text-gray-900 {{ candidate.phone }}

      div(class='sm:col-span-1')
        dt.text-sm.font-medium.text-gray-500 Address
        dd.mt-1.text-sm.text-gray-900 {{ candidate.fullAddress }}

    .mt-8.mb-4.text-sm.font-medium.text-gray-500 Treasurer

    dl(class='sm:grid-cols-2 grid grid-cols-1 gap-x-4 gap-y-8')
      div(class='sm:col-span-1')
        dt.text-sm.font-medium.text-gray-500 Full name
        dd.mt-1.text-sm.text-gray-900 {{ candidate.fullTreasurerName }}

      div(class='sm:col-span-1')
        dt.text-sm.font-medium.text-gray-500 Address
        dd.mt-1.text-sm.text-gray-900 {{ candidate.fullTreasurerAddress }}

    v-divider.mt-5

    .d-flex.justify-between.align-end.mb-1.mt-16
      h5 Campaign
      v-btn(
        color="primary"
        small
        depressed
        @click="openCampaignEdit = true"
        class="group-hover:opacity-100"
      )
        | update
    v-divider.mb-5

    dl(class='sm:grid-cols-2 grid grid-cols-1 gap-x-4 gap-y-8')
      div(class='sm:col-span-1')
        dt.text-sm.font-medium.text-gray-500 Chamber
        dd.mt-1.text-sm.text-gray-900 {{ candidate.campaign.formattedChamber }}

      div(class='sm:col-span-1')
        dt.text-sm.font-medium.text-gray-500 District
        dd.mt-1.text-sm.text-gray-900 {{ candidate.campaign.districtNumber }}
        
      div(class='sm:col-span-1')
        dt.text-sm.font-medium.text-gray-500 Support Level
        dd.mt-1.text-sm.text-gray-900 {{ candidate.campaign.supportLevel | capitalize }}

      div(class='sm:col-span-1')
        dt.text-sm.font-medium.text-gray-500 Party
        dd.mt-1.text-sm.text-gray-900 {{ candidate.campaign.party | capitalize }}

      div(class='sm:col-span-1')
        dt.text-sm.font-medium.text-gray-500 Primary Election
        dd.mt-1.text-sm.text-gray-900 
          | {{ $day(candidate.campaign.primaryElectionDate).format(formats.DATE) }}

      div(class='sm:col-span-1')
        dt.text-sm.font-medium.text-gray-500 General Election
        dd.mt-1.text-sm.text-gray-900 
          | {{ $day(candidate.campaign.generalElectionDate).format(formats.DATE)  }}

    v-divider.mt-5

    .dialogs-portal
      CandidateEditDialog(
        :isOpen="openCandidateEdit"
        @close="openCandidateEdit = false"
        :item="candidate"
        v-if="openCandidateEdit"
      )

      CampaignEditDialog(
        :isOpen="openCampaignEdit"
        @close="openCampaignEdit = false"
        v-if="openCampaignEdit"
        :item="candidate.campaign"
      )

</template>

<script>
import { isOrganization } from '@/helpers/identityHelper'
import { formats } from '@/helpers/data'
import { ref, onMounted } from '@vue/composition-api'
import FormWrapper from '@/components/FormWrapper'
import AddressForm from '@/components/Address.Form';
import DatePickerDialog from '@/components/DatePicker.Dialog'
import CandidateEditDialog from '@/views/candidates/Candidate.Edit.Dialog'
import CampaignEditDialog from '@/views/campaigns/Campaign.Edit.Dialog'

export default {
  name: 'CandidateEdit',

  components: {
    FormWrapper,
    AddressForm,
    DatePickerDialog,
    CandidateEditDialog,
    CampaignEditDialog
  },

  setup(props, { root: { $store, $FeathersVuex } }) { 
    const { Candidate } = $FeathersVuex.api;
    const candidate = ref(null);
    const isPending = ref(false);

    onMounted(async () => {
      isPending.value = true;

      const { data: [candidateFind] } = await $store.dispatch('candidates/find', {})

      candidate.value = await $store.dispatch('candidates/get', [candidateFind.id, {
        query: { $eager: '[campaign]' }
      }])
      isPending.value = false;
    });

    const openCandidateEdit = ref(false);
    const openCampaignEdit = ref(false);

    return {
      candidate,
      isPending,

      // dailogs
      openCandidateEdit,
      openCampaignEdit,

      formats,

      isOrganization
    }
  }
}
</script>

<style>

</style>