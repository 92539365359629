<template lang="pug">
.form-wrapper
  FeathersVuexFormWrapper(
    :item="item"
    :eager="false"
  )
    template( v-slot="{ save, reset, clone }" )
      v-form( 
        v-model="valid"
        @submit.prevent="saveForm({ save, reset, clone, modelName: item.constructor.name })"
      )
        slot(
          v-bind:save="save" 
          v-bind:reset="reset" 
          v-bind:clone="clone" 
          v-bind:rules="rules"
          v-bind:valid="valid"
        )
</template>

<script>
import formValidations from '@/helpers/formValidations'

export default {
  name: 'FormWrapper',
  
  props: {
    item: {
      type: Object,
      required: true
    }
  },

  setup(props, { emit }) {
    const { valid, rules } = formValidations()
    const saveForm = (options) => emit('submit', options)

    return { 
      valid,
      rules,
      saveForm
    }
  }
}
</script>